@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Open Sans', sans-serif;
}

body {
  background-color: #faf9f8;
}

body.body-login {
  background-image: url('assets/img/WeKnow_Login_BG.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

body.body-profile {
  background-color: #e2ddd9;
}
body.body-home {
  background-color: #fff;
}
.border-weknow {
  border-color: #ff7321 !important;
}

.body-404 {
  background-image: url('assets/img/WeKnow_Login_BG.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.full-height-with-header {
  height: calc(100vh - 64px);
}

.list-item-header {
  background-color: #f9fafb;
}

.breadcrumbs > *:after {
  padding-left: 6px;
  padding-right: 6px;
  content: '/';
}
.light-bg {
  background-color: #d8d6d4;
}

.breadcrumbs > *:last-child {
  color: #555 !important;
}

.breadcrumbs > *:last-child:after {
  content: '' !important;
}

.spinner {
  position: absolute;
  height: 90vh;
  width: 100%;
  display: flex;
  justify-self: center;
  align-self: center;
}
.spinner-low {
  width: 100%;
  display: flex;
  justify-self: center;
  align-self: center;
}

input[type='range'] {
  accent-color: #ff7321;
}

.button-spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12.5px 0 0 -12.5px;
  width: 25px;
  height: 25px;

  & .path {
    stroke: rgb(255, 255, 255);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

.button-spinner.orange {
  & .path {
    stroke: #ff7321 !important;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
div.wek-button-holder {
  position: relative;
  line-height: 0;
  & div {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 10;
  }
}
div.wek-button-holder.modest {
  display: inline-block;
}

div.wek-button-holder > button {
  position: relative;
  z-index: 0;
}
body.body-home main > div {
  padding-bottom: 0 !important;
}
div.quill-html-output ol {
  list-style-type: decimal;
}
div.quill-html-output ul {
  list-style-type: disc;
}
div.quill-html-output ol,
div.quill-html-output ul {
  margin: 4px 0 0 20px !important;
  padding: 0 0 0 10px !important;
}
div.quill-html-output a {
  text-decoration: underline !important;
  opacity: 1;
  transition: all 200ms ease;
}
div.quill-html-output a:hover {
  opacity: 0.6;
}
div.ql-editor {
  background-color: white;
}
